<template>
    <div class="school-home flex-column">
        <!-- <headers></headers> -->
        <div class="school-home-wrapper">
            <!-- <el-breadcrumb separator="/" class="bread-crumb">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: `/main/${$route.params.id}/project`}">项目主页</el-breadcrumb-item>
                <el-breadcrumb-item>精品课程列表</el-breadcrumb-item>
            </el-breadcrumb> -->
            <div class="school-list" v-if="courseList.length > 0">
                <ul class="flex-align card-ul">
                    <li v-for="(item, index) in courseList" :key="index" class="card csp" @click="toDeatil(item,index)">
                        <div class="status flex-align-between">
                            <!-- 我的课程 -->
                            <div class="kind">
                                <img class="course-type-image"
                                    :src="item.lessonsPattern === 0? require('@/assets/images/course/syncourse.png') : item.lessonsPattern === 1 ? require('@/assets/images/course/asyncourse.png') : require('@/assets/images/course/opendourse.png')"
                                    alt="" />
                            </div>
                        </div>
                        <div class="img-wrapper">
                            <img :src="downloadURL + item.cover"
                                :onerror="$store.state.course.defaultCourseCover[index % 6]" alt />
                        </div>
                        <div class="flex-column-between info">
                            <h3 class="title wes-2">{{ item.name || item.themeName}}</h3>
                            <!-- 我的课程 -->
                            <div @click="toDeatil(item,index)">
                                <div class="speaker">主讲老师：{{item.teacherName}}</div>
                                <div class="time">
                                    上课时间：{{item.timeScope || '--'}}
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <pagination v-show="pages.total > 0" :total="pages.total" :page.sync="pages.currentPage"
                    :limit.sync="pages.pageSize" :pageSizes="[12,24,36]" @pagination="handlePageChange">
                </pagination>
            </div>
            <!-- 无课程 -->
            <Empty :show="courseList && courseList.length === 0"></Empty>
        </div>
        <!-- <Footer></Footer> -->
    </div>
</template>

<script>
    export default {
        data() {
            return {
                pages: { //分页
                    currentPage: 1,
                    pageSize: 12,
                    total: 0,
                },
                courseList: [],
            }
        },
        // 导航离开该组件的对应路由时调用
        // beforeRouteLeave(to, from, next) {
        //     // 可以访问组件实例 `this`
        //     // console.log(this, 'beforeRouteLeave'); //当前组件实例
        //     // console.log(to, '组件独享守卫beforeRouteLeave  to ');
        //     // console.log(from, '组件独享守卫beforeRouteLeave from');
        //     // console.log(next, '组件独享守卫beforeRouteLeave第三个参数');
        //     if (to && to.name != 'course') {}
        //     next();

        // },
        methods: {
            // 翻页
            handlePageChange(data) {
                this.pages.currentPage = data.pageIndex
                this.pages.pageSize = data.pageSize
                // console.log(this.pages);
                this.getListBoutique();
            },
            async getListBoutique() {
                this.courseList = [];
                let params = {
                    page: this.pages.currentPage,
                    limit: this.pages.pageSize,
                    projectId: this.$route.params.id,
                }
                let resData = await this.$Api.Project.getListBoutique(params);
                // console.log(resData);
                if (JSON.stringify(resData.data) == '{}' || !resData) return;
                this.courseList = resData.data.list;
                this.pages.total = resData.data.totalCount;
            },

            // 切换筛选项
            // 去详情页
            toDeatil(item, index) {
                // console.log(item)
                this.$router.push({
                    path: `/course/${item.id}`,
                    query: {
                        proid: this.$route.params.id,
                        index: index,
                        breadNumber:4,
                    }
                });
            },
        },
        // mounted() {
        //   this.getUserProject();
        //   this.getSubjectList();
        // },
        mounted() {
            this.getListBoutique();
        }
    };
</script>

<style lang="less" scoped>
    .school-home {
        min-height: 100vh;
        // background-color: rgba(249, 249, 249, 1);


        .school-home-wrapper {
            width: 990px;
            margin: 0 auto 79px;
            flex: 1;
        }

        // 筛选条件
        .school-filter {
            margin: 38px 0 22px;
            background-color: #fff;
            border-radius: 10px;


            .filter-row {
                padding-left: 39px;
                border: 1px dashed rgba(191, 191, 191, .12);

                // position: relative;
                &:last-child {
                    border: 0
                }

            }

            .filter-row-name {
                min-width: 60px;
                line-height: 50px;
                color: #555;
            }

            .filter-row-ul-wrapper {
                height: 50px;
                overflow: hidden;
                -webkit-transition: height .6s;
                -moz-transition: height .6s;
                -ms-transition: height .6s;
                -o-transition: height .6s;
                transition: height .6s;

                // &.unflod-row {
                //   height: 150px;
                // }
            }

            .filter-row-ul {
                flex-grow: 1;
            }

            .filter-row-item {
                display: inline-block;
                margin: 0 10px 0px 0;
                padding: 0 20px;
                line-height: 50px;
                color: #999;

                &.active {
                    color: #508EF9;
                }
            }

            .flod {
                padding-right: 15px;
                font-size: 15px;
                line-height: 50px;
                color: #999;
            }
        }

        // 排序
        .school-sort {
            background-color: #fff;
            border-radius: 10px;
            line-height: 40px;
            margin-top: 22px;
            padding: 0 15px 0 24px;

            .sort-ul {
                li {
                    display: inline-block;
                    margin-right: 10px;
                    padding: 0 15px;
                    color: #999;
                }

                li.active {
                    color: #fff;
                    background-color: rgba(80, 142, 249, 1);
                }
            }

            .search {
                display: inline-block;

                /deep/ .el-input__inner {
                    border: 1px solid #CECECE;
                    border-radius: 25px;
                    line-height: 24px;
                    height: 24px;
                    color: #343434;
                }
            }
        }

        // 课程列表
        .school-list {
            margin-top: 25px;

            .card-ul {
                flex-flow: row wrap;

                .card {
                    position: relative;
                    box-sizing: border-box;
                    margin: 0 11px 22px 0;
                    border-radius: 10px;
                    padding: 0 0 10px 0;
                    max-width: 239px;
                    width: 24.5%;
                    background-color: #fff;

                    &:nth-child(4n) {
                        margin-right: 0px;
                    }
                }

                //li上部分
                .status {
                    box-sizing: border-box;

                    .kind {
                        position: absolute;
                        top: 0;
                        right: 0;

                        .course-type-image {
                            width: 50px;
                        }
                    }

                    .kind-type {
                        position: absolute;
                        top: 14px;
                        left: 0;

                        text-align: center;
                        line-height: 24px;
                        font-size: 14px;
                        padding: 2px 12px 2px 7px;
                        height: 24px;
                        border-radius: 2px 12px 12px 2px;
                        color: #ffffff;
                        // color: #2474ec;
                    }

                    .background-color-1 {
                        background: #F6B926;
                    }

                    .background-color-2 {
                        background: #36C2CF;
                    }

                    .background-color-3 {
                        background: #EF5757;
                    }

                    .countdown {
                        color: #ff1a1a;
                    }
                }

                //中间图片
                .img-wrapper {
                    width: 100%;
                    height: 128px;
                    margin: 0px auto 5px;
                    overflow: hidden;
                    // border-radius: 3px;

                    img {
                        width: 100%;
                        max-width: 100%;
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;
                    }
                }


                //li下部分
                h3.title {
                    margin-bottom: 5px;
                    height: 38px;
                    font-size: 13px;
                    padding: 0 11px;
                    font-weight: bold;
                }


                .speaker,
                .integral,
                .time {
                    padding-bottom: 3px;
                    color: #595959;
                    font-size: 12px;
                    padding: 0 11px;
                    line-height: 20px;
                }

                .other {
                    margin-top: 10px;

                    .otherJob {
                        font-size: 15px;
                        color: #888;
                    }

                    .toDetail {
                        max-width: 119px;
                        padding: 0 32px;
                        text-align: center;
                        background: linear-gradient(90deg, #2272ec, #3a7bf6);
                        box-shadow: 0px 5px 13px 0px rgba(22, 115, 255, 0.28);
                        border-radius: 5px;
                        line-height: 31px;
                        color: #ffffff;
                    }
                }
            }

            @media screen and (min-width: 1250px) {
                .card-list {
                    padding: 20px 22px;
                }

                .card-ul {
                    .card {
                        margin: 0 13px 29px 0;
                        padding: 0 0 15px 0;
                        max-width: 290px;
                    }

                    .img-wrapper {
                        height: 155px;
                        margin: 0px auto 10px;
                    }

                    h3.title {
                        margin-bottom: 10px;
                        font-size: 15px;
                        height: 44px;
                    }
                }
            }
        }

        @media screen and (min-width:1250px) {
            .school-home-wrapper {
                width: 1200px;
                margin: 0 auto 95px;
            }
        }

    }
</style>